
import { defineComponent, ref } from 'vue';
import dataStore from '@/services/DataStore';
import webcamProvider from '@/services/WebcamProvider';
import AzureUploader from '@/services/AzureUploader';

import WebcamBox from './WebcamBox.vue';

export default defineComponent({
  name: 'UploadScreen',
  components: { WebcamBox },
  setup() {

    const state = ref('Record' as 'Record' | 'Review');

    const lastRecordedBlob = ref(null as Blob | null);
    const recordedObjectUrl = ref('');
    const isUploading = ref(false);
    const uploadError = ref(null as null | any);

    return {
      state,
      dataStore,
      webcamProvider,
      webcamStatus: webcamProvider.webcamStatus,
      isRecording: webcamProvider.isRecording,
      lastRecordedBlob,
      recordedObjectUrl,
      isUploading,
      uploadError,
    };
  },
  methods: {
    async toggleRecording() {
      if (!webcamProvider.isRecording.value) {
        await webcamProvider.startWebcam();
        await webcamProvider.startRecording();
      } else {
        this.lastRecordedBlob = await webcamProvider.stopRecording();
        this.recordedObjectUrl = URL.createObjectURL(this.lastRecordedBlob);
        this.state = 'Review';
        await webcamProvider.stopWebcam();
      }
    },
    async rerecord() {
      this.lastRecordedBlob = null;
      this.recordedObjectUrl = '';
      this.uploadError = null;
      this.state = 'Record';
      await webcamProvider.startWebcam();
    },
    async uploadVideo() {
      if (!this.lastRecordedBlob) return;
      if (this.isUploading) return;

      this.isUploading = true;
      this.uploadError = null;
      const blobName = `${dataStore.experimentName}-${dataStore.participantId}-${dataStore.uploadName}.webm`;

      try {
        await AzureUploader.upload(this.lastRecordedBlob, blobName);
      } catch (e) {
        console.error('Error uploading video', e);
        this.uploadError = e;
      }

      if (this.uploadError === null) {
        this.uploadError = 'Upload Successful';
      }

      this.isUploading = false;
    },
  },
});

